import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './quote.module.css'

export default ({ quote, quoteImage, sectionCount }) => (
  <section className="pull-left bkd-jade">
    <div className={styles.quote}>
      <div className={styles.quoteImageWrap}>
        <GatsbyImage image={quoteImage} className={styles.heroImage} alt="" />
      </div>
      <div className={styles.quoteTextWrap}>
        <header>
          <p className="section-number">0{sectionCount}</p>
          <h2 id="quote">Quote</h2>
        </header>
        <div className="mt">
          <div
            className="inline quote-wrap pull-text"
            dangerouslySetInnerHTML={{
              __html: quote,
            }}
          />
          <p className="no-mb">Angie Thomas</p>
        </div>
      </div>
    </div>
  </section>
)
