import React from 'react'
import Award from './award'
import { library } from '@fortawesome/fontawesome-svg-core'
import caretL from '../../svg/caret-l.svg'
import caretR from '../../svg/caret-r.svg'
import Carousel from 'react-slick'
import '../slick.css'
import '../slick-theme.css'
import * as styles from './awards.module.css'

// https://react-slick.neostack.com/docs/example/custom-arrows
function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Next">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretR})`,
          }}
        ></span>
      </a>
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <a href="#" title="Previous">
        <span
          className="caret"
          style={{
            backgroundImage: `url(${caretL})`,
          }}
        ></span>
      </a>
    </div>
  )
}

const carouselSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const carouselMultiple = {
  slidesToShow: 4,
  slidesToScroll: 4,
}

const carouselSingle = {
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default ({ awards }) => (
  <section className="headless bkd-near-black">
    <div className={styles.carouselWrapperSingle}>
      <Carousel {...carouselSettings} {...carouselSingle}>
        {awards.map((award) => {
          return (
            <div key={award.id} className={styles.carouselSlide}>
              <div className={styles.carouselSlideInner}>
                <Award award={award} />
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
    {awards.length > 4 ? (
      <div className={styles.carouselWrapper}>
        <Carousel {...carouselSettings} {...carouselMultiple}>
          {awards.map((award) => {
            return (
              <div key={award.id} className={styles.carouselSlide}>
                <div className={styles.carouselSlideInner}>
                  <Award award={award} />
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    ) : (
      <div className={styles.carouselWrapperFlex}>
        {awards.map((award) => {
          return (
            <div key={award.id} className={styles.carouselSlide}>
              <div className={styles.carouselSlideInner}>
                <Award award={award} />
              </div>
            </div>
          )
        })}
      </div>
    )}
  </section>
)
