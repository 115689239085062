import React from 'react'
import ribbon from '../../svg/ribbon.svg'

import * as styles from './award.module.css'

export default ({ award }) => (
  <>
    {award.year && <h4>{award.year}</h4>}
    <h4>{award.awardName}</h4>
    <h5 className="accent">{award.category}</h5>
    <span
      className={styles.ribbon}
      style={{
        backgroundImage: `url(${ribbon})`,
      }}
    ></span>
  </>
)
