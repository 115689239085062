import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useSiteMetadata from '../hooks/use-site-metadata'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

import * as styles from './top-social.module.css'

export default function TopSocial({ heroImage, heroTitle }) {
  const { facebook, instagram, twitter } = useSiteMetadata()
  return (
    <section className="top-social">
      <div className={styles.hero}>
        <header>
          <h1 className="hero-title">{heroTitle}</h1>
          <div className={styles.heroSocial}>
            <h3>Stay Informed</h3>
            <ul className={styles.social}>
              <li>
                <a href={`https://www.facebook.com/${facebook}`}>
                  <span>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </span>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </li>
              <li>
                <a href={`https://www.instagram.com/${instagram}`}>
                  <span>
                    <FontAwesomeIcon icon={faInstagram} />
                  </span>
                  <span className="visually-hidden">Instagram</span>
                </a>
              </li>
              <li>
                <a href={`https://www.twitter.com/${twitter}`}>
                  <span>
                    <FontAwesomeIcon icon={faTwitter} />
                  </span>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <div className={styles.heroImageWrap}>
          <GatsbyImage image={heroImage} className={styles.heroImage} alt="" />
        </div>
      </div>
    </section>
  )
}
