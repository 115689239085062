import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import TopSocial from '../components/top-social'
import Awards from '../components/awards/awards'
import Quote from '../components/quote'
// import Instagram from '../components/instagram/grams'

class AboutPage extends React.Component {
  render() {
    const about = this.props.data.contentfulAbout
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={about.title} />
          <TopSocial
            heroImage={about.heroImage.gatsbyImageData}
            heroTitle={about.heroTitle}
          />
          <section>
            <header>
              <p className="section-number">0{(sectionCount += 1)}</p>
              <h2 id="my-story">My Story</h2>
            </header>
            <div
              className="section-description mt"
              dangerouslySetInnerHTML={{
                __html: about.myStory.childMarkdownRemark.html,
              }}
            />
          </section>
          {about.awards && <Awards awards={about.awards} />}
          <Quote
            quote={about.quote.childMarkdownRemark.html}
            quoteImage={about.quoteImage.gatsbyImageData}
            sectionCount={(sectionCount += 1)}
          />
          <section>
            <header>
              <p className="section-number">0{(sectionCount += 1)}</p>
              <h2 id="press-kit">Press Kit</h2>
            </header>
            <div
              className="section-description mt"
              dangerouslySetInnerHTML={{
                __html: about.pressKit.childMarkdownRemark.html,
              }}
            />
            <Link
              className="button mt section-description mobile-full"
              to="/press-kit/"
            >
              Press Kit
            </Link>
          </section>
          {/* <Instagram sectionCount={sectionCount} /> */}
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAbout(id: { eq: "f34952d8-f0ee-59dd-950e-785cf3ada390" }) {
      awards {
        awardName
        category
        id
        year
      }
      heroImage {
        gatsbyImageData(width: 1122, layout: CONSTRAINED)
      }
      heroTitle
      myStory {
        childMarkdownRemark {
          html
        }
      }
      pressKit {
        childMarkdownRemark {
          html
        }
      }
      quote {
        childMarkdownRemark {
          html
        }
      }
      quoteImage {
        gatsbyImageData(width: 969, layout: CONSTRAINED)
      }
      title
    }
  }
`
